import React from 'react';
import Title from './title';
import styled from 'styled-components';
import FixedTitleInfo from "./FixedInfo/info";
import FixedInfoTitle from "./FixedInfo/title";
import { CircularProgress } from "@mui/material";


const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    background-color: #171717;
    align-items: center;
    width: 100%;
`

const InfoContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: row;
    background-color: #262730;
    align-items: center;
`

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 768px;
`

const Image = styled.img`
    width: 786px;
    height: 786px;
`

const ImageInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 741px;
    height: 786px;
    padding: 24px 37px 0 41px;
`

const GroupTitle = styled.span`
    font-size: 21px;
    letter-spacing: 3.15px;
    color: #fff;
    padding: 10px 0;
    margin-top: 10px;
`

const TAContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const UseContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`

const TABtn = styled.button`
    background: ${props => props.focus ? 'rgba(122, 128, 183, 0.5)' : '#0d0d0d'};
    border: 1px solid ${props => props.focus ? '#7a80b7' : '#0d0d0d'};
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    flex: 0 1 calc(33.333% - 23px);
    line-height: normal;
    letter-spacing: 4.32px;
    margin: 11px 8px 12px 8px;
    padding: 10px;
`

const SubmitBtn = styled.button`
    width: 100%;
    height: 42.7px;
    flex-grow: 0;
    margin: 20px 25px 0 0;
    border-radius: 10px;
    border: none;
    background-color: #7e7f81;
    color: #fff;
`

const HomePage = ({ loading, imageUrl, keyword, region, ta, setTa, willUse, setWillUse, onSubmit }) => {
    const TA_OPTIONS = [
        '輕度智能障礙者',
        '中度智能障礙者',
        '重度智能障礙者',
        '自閉症',
        '長者',
        '大眾',
        '其他',
        '都不適合'
    ]
    const WILL_USE_OPTIONS = [
        '不會',
        '可能會',
        '會'
    ]
    const imageArea = loading ? <CircularProgress/> : <Image src={imageUrl} alt="drawing" />
    return (
        <Container>
            {
                loading ? <CircularProgress/> : null
            }
            <InfoContainer>
                <ImageContainer>
                    {imageArea}
                </ImageContainer>
                <ImageInfoContainer>
                    <FixedInfoTitle>搭配圖片文字</FixedInfoTitle>
                    <FixedTitleInfo>{keyword}</FixedTitleInfo>
                    <FixedInfoTitle>文化地區</FixedInfoTitle>
                    <FixedTitleInfo>{region}</FixedTitleInfo>
                    <GroupTitle>你覺得這張圖片適合給哪些族群閱讀?</GroupTitle>
                    <TAContainer>
                        {
                            TA_OPTIONS.map((option, index) => {
                                return (
                                    <TABtn
                                        key={index}
                                        focus={ta === option}
                                        onClick={() => setTa(option)}
                                    >
                                        {option}
                                    </TABtn>
                                )
                            })
                        }
                    </TAContainer>
                    <GroupTitle>你會實際運用這張圖嗎?</GroupTitle>
                    <UseContainer>
                        {
                            WILL_USE_OPTIONS.map((option, index) => {
                                return (
                                    <TABtn
                                        key={index}
                                        focus={willUse === option}
                                        onClick={() => setWillUse(option)}
                                    >
                                        {option}
                                    </TABtn>
                                )
                            })
                        }
                    </UseContainer>
                    <SubmitBtn onClick={onSubmit}>返回圖庫</SubmitBtn>

                </ImageInfoContainer>
            </InfoContainer>
        </Container>
    );
};

export default HomePage;