import React, {useEffect, useState} from 'react';
import { CircularProgress, Select, MenuItem, InputLabel, FormControl, TextField, Button, Box, Typography } from '@mui/material';
import styled from "styled-components";
import { style } from '@mui/system';

const REGION_LIST = [
  '台灣',
  '歐洲',
  '日本'
]

const AGE_LIST = [
  '兒童',
  '青少年',
  '成人'
]

const TopHeader = styled.span`
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 8.4px;
`

const PromptField = styled.input`
  font-size: 18px;
  &::placeholder {
    color: #7e7f81; // 設置placeholder文字的顏色
    opacity: 1; // 確保顏色的不透明度讓顏色正常顯示
  }
  width: 719px;
  height: 189px;
  margin: 33px 0 0;
  padding: 20px 165px 109px 20px;
  border-radius: 10px;
  color: #1c1d1e;
  background-color: #c7c7c7;
`

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const SplitLine = styled.hr`
  width: 100%;
  background: #7E7F81;

`

const StepSpan = styled.span`
  font-size: 1.5rem;
  font-weight: bold;

`

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const OptionButton = ({ onClick, focus, value, children }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{ margin: 1, background: focus ? '#7A80B7' : '#0D0D0D', color: 'white', borderRadius: '7px', padding: '0.7rem 3rem', fontSize: '1.2rem', width: '10rem'}}
    >
      {children}
    </Button>
  )
}



const Step = ({ step, comment }) => {
  const StepContainer = styled.div`
    display: flex;
    align-items: flex-end;
    margin: 1.3rem 0;
    `
  const CommentSpan = styled.span`
    margin: 0 0.7rem 0.1rem 0.7rem;
    font-size: 21px;
    color: #646668;
    font-weight: 500;
  `
  const StepSpan = styled.span`
    font-size: 21px;
    font-weight: 300;
  `
  return (
    <StepContainer>
      <StepSpan>{step}</StepSpan>
      <CommentSpan>{comment}</CommentSpan>
    </StepContainer>
  );
}

const GenerateImage = ({ onSubmit, loading }) => {
  const [prompt, setPrompt] = useState('')
  const [region, setRegion] = useState('台灣')
  const [age, setAge] = useState('兒童')

  return (
    <Box sx={{ color: 'white', padding: 3, width: '50%', background: '#171717', maxWidth: '780px' }}>
      <form onSubmit={(e) => {
        e.preventDefault()
        onSubmit({ prompt, region, age })
      }}>
        <TopHeader>只要3步驟，就可以獲得易讀圖片囉！</TopHeader>
        <SplitLine />
        <StepContainer>
          <Step step='STEP 1. 輸入要搭配圖片的文字' />
          <PromptField
            placeholder="多描述一些，更有機會得到合適的圖片喔！"
            // style={{
            //   background: '#c7c7c7',
            //   borderRadius: '15px',
            //   '& .MuiInputBase-input::placeholder': { // 直接針對input元素的placeholder樣式進行定制
            //     color: 'red', // 將顏色設定為紅色
            //     opacity: 1, // 設定不透明度為1來確保顏色正常顯示
            //   },
            // }}
            // margin="normal"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            // rows={4}
          />
          <Step step='STEP 2. 請選擇圖片文化地區' comment='(將有助於產出符合的圖片)' />
          <OptionContainer>
            {
              REGION_LIST.map((item) => (
                <OptionButton
                  key={item}
                  focus={region === item}
                  onClick={() => setRegion(item)}
                >
                  {item}
                </OptionButton>
              ))
            }
          </OptionContainer>
          <Step step='STEP 3. 請選擇圖片閱讀對象年齡' comment='(將有助於產出符合的圖片)' />
          <OptionContainer>
            {
              AGE_LIST.map((item) => (
                <OptionButton
                  key={item}
                  focus={age === item}
                  onClick={() => setAge(item)}
                >
                  {item}
                </OptionButton>
              ))
            }
          </OptionContainer>

          <Button type="submit" variant="contained" margin="normal" style={{ width: '100%', margin: '1rem 0', background: '#7E7F81', color: 'white', fontSize: '1.3rem' }}>
            {
              loading &&
              <CircularProgress />
            }
            {'生成圖片'}
          </Button>
        </StepContainer>
      </form>
    </Box>
  );
};

export default GenerateImage;
