import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import copy from 'copy-to-clipboard';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ImageInfo from './ImageList/Info';
import {maxWidth} from "@mui/system";

const GalleryContainer = styled.div`
  display: flex;
  height: auto;
  width: 100vw;
    justify-content: center;
    background: rgba(12, 12, 12, 0.7);
`;

const Gallery = ({ hasMore, imageItems, moreImage }) => {
  const navigate = useNavigate();
  const width = window.innerWidth;
  let maxWidth = '407px'
if (width < 1400) {
    maxWidth = '262.7px'
}
  const [open, setOpen] = useState(false);

  return (
    <GalleryContainer>
      <InfiniteScroll
        dataLength={imageItems.length}
        next={moreImage}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <ImageList sx={{  height: 'auto',  margin: 'auto', maxWidth: width > 1500 ? '80vw': '90vw' }} cols={4}>
          {imageItems.map((item, index) => (
            <ImageListItem key={item.id} style={{ maxWidth, margin: '0.5rem', borderRadius: '5px' }}>
              <img
                src={item.imageUrl}
                alt={item.keyword}
                onClick={() => navigate(`/article/${item.id}`)}
                loading="lazy"
                style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '15px', width: '407px', height: '407px'}}
              />
              <ImageInfo {...item} />
            </ImageListItem>
          ))}
        </ImageList>
      </InfiniteScroll>
    </GalleryContainer>
  );
};

export default Gallery;
