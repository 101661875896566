import { useState } from "react";
import styled from "styled-components";
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import ShareIcon from '@mui/icons-material/Share';
import copy from 'copy-to-clipboard';
import Snackbar from '@mui/material/Snackbar';


const ArticleInfoContainer = styled.div`
    position: relative;
    left: 0;
    right: 0;
    display: flex;
    background: #262730;
    flex-direction: column;
    border-radius: 0 0 15px 15px;
    padding: 1rem 1.5rem;
`

const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0;
`
const Username = styled.div`
    font-size: 1.1rem;
    color: #FFFFFF;
    padding: 0 0.7rem;
    font-weight: 600;
`

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const UserInfo = ({ username, photoUrl, id }) => {
  return (
    <BottomContainer>
      <UserInfoContainer>
        <Avatar sx={{width: 30, height: 30}} src={photoUrl} alt={username} />
        <Username>{username}</Username>
      </UserInfoContainer>
      <ShareButton itemId={id} />
    </BottomContainer>
  )
}

const ImageInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const InfoFiledContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.1rem;
    padding: 0.7rem 0 0.3rem 0;
`

const ContentContainer = styled.span`
    max-width: 280px;
    overflow: hidden;
    font-size: 1.3rem;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (max-width: 1500px) {
        max-width: 228px;
    } ()
`

const TargetContainer = styled.div`
  max-width: 210px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #2F2F2F;
  padding: 0.3rem 1.4rem;
  border-radius: 600px;
`

const SplitLine = styled.hr`
    width: 100%;
    background: #7E7F81;


`

const ShareButton = ({ itemId }) => {
  const [open, setOpen] = useState(false);
  const handleShare = (url) => {
    // 實現分享功能
    copy(url);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  }
  return (
    <IconButton
      size='large'
      onClick={() => handleShare(`${window.location.hostname}/article/${itemId}`)}
      sx={{
        background: 'rgba(0,0,0,0.05)',
        margin: '0.3rem',

      }}
    >
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="連結已複製"
      />
      <ShareIcon />
    </IconButton>
  )
}

const ImageInfo = ({ keyword, tags }) => {
  const firstTag = tags.trim().split(',')[0];
  return (
      <ImageInfoContainer>
          <ContentContainer>{keyword}</ContentContainer>
          <InfoFiledContainer>
            <span>目標對象</span>
            <span style={{ margin: '0.3rem 0.3rem' }}>:</span>
            {
              firstTag.length > 1
              ? <TargetContainer>{firstTag}</TargetContainer>
              : null
            }
          </InfoFiledContainer>
      </ImageInfoContainer>
  )
}


const ArticleInfo = ({ id, authorName, authorImage, keyword, tags, ta }) => {
  return (
    <ArticleInfoContainer>
      <ImageInfo keyword={keyword} tags={ta} />
      <SplitLine />
      <UserInfo id={id} username={authorName} photoUrl={authorImage} />
      
    </ArticleInfoContainer>
  )
}

export default ArticleInfo
