import styled from 'styled-components';

const Title = styled.h1`
    font-family: NotoSansBengaliUI;
    font-size: 35px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 8.4px;
    text-align: center;
    color: #fff;
`

export default Title