import Loading from "../components/loading/index";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getArticle, UpdateTaAndWillUse } from "../actions/actions";
import {createSocket, subscribeTask} from "../utils/listener";

const subscribe = ({ taskId, setArticleId, setLoading, setListener }) => {
    const eventListener = createSocket({ onMessage: (msg) => {
            const {taskID: doneTaskId, articleID} = msg
            if (doneTaskId === taskId) {
                setLoading(false);
                setArticleId(articleID);
            }

        }, onOpen: () => {
            subscribeTask({taskId, socket: eventListener})
        }});

    setListener(eventListener)
    return
}

const LoadingImpl = () => {
    const { taskId } = useParams()
    const [listener, setListener] = useState(null);
    const [articleId, setArticleId] = useState('')

    const [article, setArticle] = useState({})
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    const [ta, setTa] = useState(article?.ta || '')
    const [willUse, setWillUse] = useState(article?.willUse || '')
    useEffect(() => {
        subscribe({ taskId, setArticleId, setLoading, setListener })
    }, [])

    useEffect(() => {
        if (!articleId) {
            return
        }
        (async () => {
            const { article: data } = await getArticle({ articleId })
            if (!data) {
                navigate('/')
            }
            if (data) {
                setArticle(data)
            }
            setLoading(false)
            return
        })()
    }, [articleId, navigate])
    const onSubmit = async () => {
        await UpdateTaAndWillUse({ articleId: article.dateId, ta, willUse })

        navigate('/')
    }
    const render = <Loading
        {...article}
        ta={ta}
        loading={loading}
        setTa={setTa}
        willUse={willUse}
        setWillUse={setWillUse}
        onSubmit={onSubmit}
    />
    return (
      <>
      {render}
      </>
    )
}

export default LoadingImpl