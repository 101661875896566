import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import {Box, styled} from "@mui/system";
import { DarkBackgroundColor, DarkborderColor, FontWhiteColor, DarkButtonColor, ButtonBorderColor } from "../settings/color"; 
import { Link } from "react-router-dom";
import SurveyDialog from "./Dialog/SurveyDialog";
import FeatureBtn from "./NavbarBtns/FeatureBtn";
import HistoryBtn from "./NavbarBtns/HistoryBtn";
import { matchRoutes, useLocation } from "react-router-dom"
import HomeBtn from "./NavbarBtns/HomeBtn";



const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledAppBar = styled(AppBar)({
  background: 'none',
  borderBottom: `1px solid ${DarkborderColor}`,
  padding: '0.5rem 0',
});

const StyledButton = styled(Button)({
  color: '#FFFFFF',
  backgroundColor: '#333333',
  fontSize: '1.3rem',
  padding: '0.5rem 1.5rem',
  border: `1px solid ${ButtonBorderColor}`,
  borderRadius: '50px',
  margin: '0 1rem',
  textDecoration: 'none',
});

const StyledTypography = styled(Typography)({
  color: FontWhiteColor,
  fontWeight: 600,
  letterSpacing: '0.2rem',
  fontSize: '2.5rem',
  textDecoration: 'none',
  fontFamily: `"Jura", sans-serif`,
});

const ToolContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const UserArea = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  color: #FFFFFF;
`

const Navbar = ({ username, picture, onLogout, openSurvey, onCloseSurvey }) => {
  let userArea = (
    <Link to="/login" style={{ textDecoration: 'none' }}>
      <UserArea>
      <Avatar />
      <StyledButton color="inherit">
        登入
      </StyledButton>
      </UserArea>
    </Link>
  )
  if (username) {
    userArea = (
      <Link to="/history" style={{ textDecoration: 'none' }}>
        <UserArea>
        <Avatar src={picture} alt={username}/>
        <span style={{ padding: '1rem' }}>
        {username}
        </span>
        </UserArea>
      </Link>
    )
  }

  const { pathname } = useLocation()
  return (
    <>
    <StyledAppBar position="static" s>
      <SurveyDialog open={openSurvey} onClose={onCloseSurvey}/>
      <StyledToolbar>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <StyledTypography  style={{ fontFamily: `Jura` }}>
            Passpark
          </StyledTypography>
        </Link>
        <ToolContainer>
          {
            username &&
            <Link to="/create">
              <StyledButton color="inherit">
                開始製作
              </StyledButton>
            </Link>
          }
          {userArea}
        </ToolContainer>
      </StyledToolbar>
      
    </StyledAppBar>
    <div style={{ height: '75px', background: '#070707', margin: '0 0 1.5rem 0', display: 'flex', alignItems: 'flex-end', justifyContent: 'start', width: '100%' }}>
      <HomeBtn focus={pathname === '/'} />
      <FeatureBtn focus={pathname === '/gallery'} />
      {
        username &&
          <HistoryBtn focus={pathname === '/history'} />

      }
    </div>
    </>
  );
};

export default Navbar;
