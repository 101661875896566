import './App.css';
import React from 'react';
import Login from './container/Login'
import Callback from './container/Callback'
import Homepage from './container/Homepage'
import Landing from './container/Landing'
import Article from './container/Article'
import Loading from './container/Loading'
import ArticleHistory from "./container/ArticleHistory";
import GenerateImage from './container/GenerateImage'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { 
  // createBrowserRouter,
  // RouterProvider,
  // Outlet,
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import {UserProvider} from "./actions/store/auth";
import Register from "./container/Register";
import Navbar from "./container/Navbar";
import styled from "styled-components";
import LandingPage from "./container/Landing";

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  background-color: #171717;
  align-items: center;
`
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
      <div className="App">
        <div id="gray-overlay"></div>
        <BrowserRouter>
          <UserProvider>
            <ThemeProvider theme={darkTheme}>
              <CssBaseline/>
              <Container className='base'>
                <Navbar/>
                <Routes>
                  <Route path="/" element={<Landing/>}/>
                  <Route path="/gallery" element={<Homepage/>}/>
                  <Route path="/login" element={<Login/>}/>
                  <Route path="/callback" element={<Callback/>}/>
                  <Route path="/register" element={<Register/>}/>
                  <Route path="/article/:articleId" element={<Article/>}/>
                  <Route path="/loading/:taskId" element={<Loading/>}/>
                  <Route path="/history" element={<ArticleHistory/>}/>
                  <Route path="create" element={<GenerateImage/>}/>
                </Routes>
              </Container>
            </ThemeProvider>
          </UserProvider>
        </BrowserRouter>
      </div>
  );
}

// const Layout = ({ children }) => () => {
//   return (
//     <div className="App">
//       <UserDataProvider>
//       </UserDataProvider>
//     </div>
//   );
// }

export default App;
