import {useEffect, useState} from 'react'
import Gallery from '../components/Gallery'
import { getImages } from '../actions/actions'
import styled from "styled-components";
import folder from '../assets/folder.svg'
import arrow from '../assets/arrow.svg'
import './Landing.css'
import BottomInfo from "./landing/bottomInfo";
import AboutUs from "./landing/aboutUs";
import {Link} from "react-router-dom";


const moreImages = async ({setImageItems }) => {
    let articles = await getImages('')
    let cols = 8
    articles = articles.slice(0, cols)
    setImageItems(articles);
    // setPage(prevPage => prevPage + 1);
}


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
`;

const Title = styled.h1`
    color: white;
    font-size: 90px;
    font-family: "Jura", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 17px;
    margin: 190px 0 17px 0;
`
const Intro = styled.p`
    font-size: 25px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.2;
    letter-spacing: 3.75px;
    text-align: center;
    color: #fff;
`

const FolderDiv = () => {
    const Icon = styled.img`
        width: 50px;
        height: 40px;
        margin-right: 35px;
    `
    const Container = styled.div`
        display: flex;
        flex-direction: row;
        width: 312px;
        align-items: center;
    `
    const IntroText = styled.span`
        font-size: 40px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 15.6px;
        text-align: left;
        color: #7e7f81;
        `

    return (
        <Container>
            <Icon src={folder}/>
            <IntroText>精選圖庫</IntroText>
        </Container>
    )
}
const FuckIntro = styled.span`
    width: 100vw;
    font-optical-sizing: auto;
    font-style: normal;
    padding: 93px 250px  50px 250px ;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Jura", sans-serif;
    font-size: 21px;
    background: rgba(12, 12, 12, 0.8);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: 5px;
    text-align: center;
    color: #fff;
`

const AAA = styled.span`
    width: 100vw;
    flex-grow: 0;
    font-family: "Jura", sans-serif;
    background: rgba(12, 12, 12, 0.8);
    font-optical-sizing: auto;
    font-style: normal;
    padding: 109px 250px 15px 250px ;
    font-size: 40px;
    font-weight: 500;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 8px;
    text-align: center;
    color: #fff;
`
const LandingPage = () => {
    const [imageItems, setImageItems] = useState([]); // 圖片數據的初始狀態
    const [page, setPage] = useState(''); // 當前頁碼
    const [hasMore, setHasMore] = useState(true); // 是否還有更多圖片可以加載
    useEffect(() => {
        moreImages({ page, setPage, setImageItems })
    }, [])
    return (
        <Container>
            <Title>AI易讀繪圖工具</Title>
            <Intro>
                輸入易讀的文字，我們一起畫出易懂的圖片
            </Intro>
            <Link to="/create" style={{ textDecoration: 'none', color: 'white' }}>

            <div style={{
                width: '531px',
                height: '80px',
                background: '#7a80b7',
                borderRadius: '6px',
                display: 'flex',
                fontSize: '30px',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '80px 0',
                cursor: 'pointer'
            }}>
                立即註冊體驗 →
            </div>
            </Link>
            <AAA>好多資訊等著被易讀易懂</AAA>
            <FuckIntro>
                在資訊爆炸的時代，我們努力將每一筆資訊轉化成易讀的內容，<br/>

                讓更多的人可以獲得新知，<br/>

                然而有了易讀的文字，卻很難找到易懂的圖片。<br/>

                有了AI，要畫出易懂的圖可以很簡單！<br/>

                只需輸入搭配圖片的文字，就能得到多組圖片。<br/>
            </FuckIntro>
            <Gallery imageItems={imageItems} hasMore={false} moreImage={() => {
            }}/>
            <div style={{background: 'rgba(12, 12, 12, 0.8)', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Link to="/create" style={{ textDecoration: 'none', color: 'white' }}>
                <div style={{
                    width: '531px',
                    height: '80px',
                    borderRadius: '6px',
                    background: '#7a80b7',
                    display: 'flex',
                    fontSize: '30px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '80px 0',
                    cursor: 'pointer'
                }}>
                    立即註冊畫圖 →
                </div>
            </Link>
            </div>

            <BottomInfo/>
            <AboutUs/>
        </Container>
    )
}

export default LandingPage