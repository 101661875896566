import { useState } from 'react';
import Navbar from '../components/Navbar';
import { useUser } from '../actions/store/auth';
import { useEffect } from 'react';
import { matchRoutes, useLocation } from "react-router-dom"

const NavbarImpl = () => {
    const { user, logout } = useUser();
    const { pathname } = useLocation()
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (user && user.needSurvey) {
            setOpen(true)
        }
    }, [user])
    return (
        <Navbar
            style={{ background: 'none' }}
            pathname={pathname}
          username={user?.name}
          picture={user?.picture}
          onLogout={logout}
          openSurvey={open}
          onCloseSurvey={() => setOpen(false)}
        />
    )
}

export default NavbarImpl;