import styled from "styled-components";
import { Link } from "react-router-dom";
import history from '../../assets/history.svg'


const Button = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: '#C7C7C7';
    padding: 0 3rem 0.5rem 17px;
    background-color: #070707;

`;

const StyledImg = styled.img`
    height: 1.7rem;
    padding: 0 5px;
`

const StyledSpan = styled.span`
    color: #C7C7C7;
    font-size: 1.5rem;
`

const FeatureBtn = ({ focus }) => {
    let borderBottomColor = focus ? '#7A80B7' : '#1F2027'
    return (
        <Link to="/history" style={{ background: '#070707', textDecoration: 'none', borderBottom: `2px solid ${borderBottomColor}` }}>
            <Button>
            <StyledImg src={history} alt='我的圖庫' />
            <StyledSpan>我的圖庫</StyledSpan>
            </Button>
        </Link>
    );
}

export default FeatureBtn