import styled from "styled-components";
import 'react-multi-carousel/lib/styles.css';
import relativeTime from 'dayjs/plugin/relativeTime'
import * as dayjs from 'dayjs'
import 'dayjs/locale/zh-tw'
import {Avatar} from "@mui/material";
import Chip from '@mui/material/Chip';
import FixedInfoTitle from "./loading/FixedInfo/title";
import FixedTitleInfo from "./loading/FixedInfo/info";


const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: row;
    background-color: #171717;
    align-items: center;
    width: 100%;
`

const InfoContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: row;
    background-color: #262730;
    align-items: center;
`

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 768px;
`

const Image = styled.img`
    width: 786px;
    height: 786px;
`

const ImageInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #262730;
    width: 741px;
    height: 786px;
    padding: 24px 37px 0 41px;
`

const GroupTitle = styled.span`
    font-size: 21px;
    letter-spacing: 3.15px;
    color: #fff;
    padding: 10px 0;
    margin-top: 10px;
`

const TAContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const UseContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`


dayjs.extend(relativeTime)

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1024px;
`

const AuthorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: 1px solid #D3D3D3;
  height: 100%;
  flex: 3;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #D3D3D3;
  width: 100%;
  background-color: #313235;
`
const AuthorMetaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
`

const AuthorInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 1rem;
  color: #F3F0E9;
`

const AuthorMetaText = styled.span`
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 400;
`

const Hr = styled.hr`
    width: 100%;
    background: #7E7F81;
    padding: 0 10px;
    margin: 250px 10px 20px 0;
`

const MetaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`


const AuthorMeta = ({ createTime, username, avatar }) => {
  const createDate = dayjs(createTime).fromNow()
  return (
      <AuthorMetaContainer>
        <MetaContainer>
        <Avatar src={avatar} >{username}</Avatar>
        <AuthorInfoContainer>
          <AuthorMetaText>{username}</AuthorMetaText>
          <AuthorMetaText>{createDate}</AuthorMetaText>
        </AuthorInfoContainer>
        </MetaContainer>
      </AuthorMetaContainer>
  )
}


const Article = ({ article }) => {
  return (
    <Container>
      <ImageContainer>
        <Image
          src={article.imageUrl}
          />
      </ImageContainer>
        <ImageInfoContainer>
            <FixedInfoTitle>搭配圖片文字</FixedInfoTitle>
            <FixedTitleInfo>{article.keyword}</FixedTitleInfo>
            <FixedInfoTitle>文化地區</FixedInfoTitle>
            <FixedTitleInfo>{article.region}</FixedTitleInfo>
            <FixedInfoTitle>圖片適用對象</FixedInfoTitle>
            <FixedTitleInfo>{article.ta}</FixedTitleInfo>
            <Hr />
            <AuthorMeta username={article.authorName} datetime={article.date} avatar={article.authorImage} />
        </ImageInfoContainer>
    </Container>
  )
}

export default Article