import styled from "styled-components";


const FixedTitleInfo = styled.span`
    width: 100%;
    font-size: 21px;
    line-height: normal;
    letter-spacing: 5.04px;
    text-align: left;
    color: #fff;
    background: #1f2027;
    padding: 23px 22px
`

export default FixedTitleInfo