import styled from "styled-components";


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 135px 0 125px 0;
`

const Title = styled.h1`
    width: 379px;
    height: 47px;
    margin: 43px 136px 126px 141px;
    font-size: 40px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 8px;
    text-align: center;
    color: rgba(248, 248, 248, 0.8);
`

const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
`

const UserInfoTitle = styled.span`
    font-family: NotoSansBengaliUI;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 11.1px;
    text-align: center;
    color: #969696;
    margin-bottom: 30px;
`

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const UserInfoText = styled.span`
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 2.25px;
    text-align: left;
    color: #fff;
    margin: 10px 0;
`

const Line = styled.div`
    width: 1px;
    height: 194px;
    background-color: #fff;
 `


const BottomInfo = () => {
    return (
        <Container>
            <Title>用戶推薦</Title>
            <UserInfoContainer>
                <UserInfo>
                    <UserInfoTitle>社工 劉小姐</UserInfoTitle>
                    <UserInfoText>有這個平台可以讓很多製作易讀文本的單位受益。</UserInfoText>
                    <UserInfoText>這些圖面很簡單，服務對象都能看得懂，</UserInfoText>
                    <UserInfoText>也可以直接使用，我覺得很棒!</UserInfoText>
                </UserInfo>
                <Line/>
                <UserInfo>
                    <UserInfoTitle>社工督導/ 許小姐</UserInfoTitle>
                    <UserInfoText>操作簡單、容易填寫，期待平台主題更多元!</UserInfoText>
                    <UserInfoText>節省第一線工作人員搜尋圖片的問題，</UserInfoText>
                    <UserInfoText>終於不用再使用Google大量搜圖了</UserInfoText>
                </UserInfo>
                <Line/>
                <UserInfo>
                    <UserInfoTitle>社工/ 翁小姐</UserInfoTitle>
                    <UserInfoText>圖片傳遞的資訊很清楚，容易看得懂</UserInfoText>
                    <UserInfoText>這樣就不用再煩惱找不到適合圖片的問題</UserInfoText>
                    <UserInfoText>網站操作也很簡單，不會太複雜</UserInfoText>
                </UserInfo>
            </UserInfoContainer>
        </Container>
    )
}

export default BottomInfo