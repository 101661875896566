import styled from 'styled-components';
import word from '../../assets/word.png'


const Container = styled.div`
    display: flex;
    padding-bottom: 75px;
    background: rgba(12, 12, 12, 0.8);
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 73px;
    width: 100vw;
    align-items: center;
`


const Span = styled.span`
    font-family: Jura;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.2;
    letter-spacing: 2.4px;
    text-align: center;
    color: #fff;
`

const TT = styled.span`
     font-family: Jura;
      font-size: 40px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 20px;
      text-align: center;
      color: #fff;
    padding:  75px 0;
`

const AboutUs = () => {
    return (
        <Container>
            <InfoContainer>
                <TT>關於我們</TT>
                <Span>在社會企業的經驗，在我們心中燃起了火花，驅動著「passpark」的成立。</Span>
                <Span>我們希望透過發揮自己所長，打破限制、開創可能</Span>
                <Span>讓可能成為火花，閃耀在每個人心中。</Span>
                <Span>團隊現有3位志工成員（產品經理、UIUX設計和全端工程師），我們真誠地接受各項建議，</Span>
                <span style={{margin:'30px'}}></span>
                <Span>若有任何問題、想法與建議，</Span>
                <Span>歡迎來信交流：pcshen99@gmail.com</Span>
            </InfoContainer>
        </Container>
    )
}

export default AboutUs